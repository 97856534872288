body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; 
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}



.App {
  position: relative;
  animation: fadeIn 1s ease-in-out;
}

.App-logo {
  width: 30mm;
  margin-top: -20px;
  margin-left: 20px;
}

.App-header {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  background-color:  transparent;
  position: fixed;
  z-index: 3;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  color: white;
}

.App-header.scrolled {
  background-color: hsl(0, 0%, 85%, 0.8);
  transition: background-color 0.7s ease;
  height: 85px;
}

.App-header.scrolled .App-logo {
  width: 40mm; 
  transition: width 0.7s ease;
}

.App-header.scrolled .Home-link,
.App-header.scrolled .About-link,
.App-header.scrolled .Contact-link,
.App-header.scrolled .Search-link {
  transition: font-size 0.7s ease;
  font-size: calc(6px + 1.2vmin);
}
