section {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

article {
    position: relative;
    overflow: hidden;
    background: #ffffff;
    width: 64px;
    height: 347px;
    border-radius: 36px;
    display: flex;
    align-items: flex-end;
    opacity: 0.9;
    transition: 0.5s;
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.8);
}

h2, p {margin:0;}

article.active {
    width:400px;
    opacity: 1;

}

article h2 {
    font-size: 14px;
    font-weight: 400;
    color: rgb(255 255 255 / 96%);
}

article p {
    color: rgb(255 255 255 / 66%);
    font-size: 14px;
    width: 45%;
}

article
.material-symbols-outlined {
    display: grid;
    place-items: center;
    width: 50px;
    height: 50px;
    background: 
        rgb(255 255 255 / 80%);
    border-radius: 50%;
    font-size: 28px;
}

article img{
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    height: 150%;
    filter: grayscale(0.5);
    
}

article .content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 400px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    padding: 100px 0 20px 20px;
    display: flex;
    align-items: center;
    gap: 14px;
    background: linear-gradient( to bottom, rgb(0 0 0 / 0%),
    rgb(0 0 0 /80%));
    transition: 0.25s;

}

article.active .content {
    opacity: 1;
    visibility: visible;
}

@media screen and (max-width: 768px) { 
    article .content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 300px;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        padding: 100px 0 20px 20px;
        display: flex;
        align-items: center;
        gap: 14px;
        background: linear-gradient( to bottom, rgb(0 0 0 / 0%), rgb(0 0 0 /80%));
        transition: 0.25s;
    }
    article h2 {
        font-size: 14px;
        width: 50%;
        font-weight: 400;
        color: rgb(255 255 255 / 96%);
    }
}