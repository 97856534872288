.aboutus-container{
    display: grid;
    grid-template-columns: 25% 75%;
    grid-gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.aboutus-main {
    background-image: url("../../assets/butterb.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 15px;
    margin-right: 12%;
    border-radius: 30px;
    justify-content: space-around;
    color: white; 
    padding: 5em 10em 1em 3em;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.6);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}


.aboutus-main h1, .aboutus-main h2 {
    color: #D0B25E; 
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .aboutus-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
    .aboutus-main {
        margin: 0;
        margin-top: 90px;
        padding: 1.5em;
        width: 80%;
        height: 600px;
        border-radius: 20px;
    }
    .aboutus-main h1, .aboutus-main h2 {
        color: #D0B25E; 
        padding-top: 5px;
        padding-bottom: 5px;
    }
}