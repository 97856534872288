.cart-container {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  width: 330px;
  height: 100%;
  background-color: #fff;
  padding: 35px 20px 20px 20px;
  overflow-y: auto;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.15);
}

.cart-container h2 {
  color: black;
  font-weight: 600;
}

.cart-container h3 {
  color: black;
  font-weight: 600;
}

.cart-item {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f4f4eb;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  height: 70px;
}

.cart-item button {
  background-color: #845c5c;
  color: #fff;
  border: none;
  padding: 4px 8px 4px 8px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
}

.cart-item button:hover {
  background-color: #c5b6b6c1;
}

.cart-item h4 {
  color: #845c5c;
  font-weight: 500;
  margin-left: 8px;
  margin-right: 10px;
}

.cart-item h3 {
  color: black;
  font-weight: 700;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 0px;
}

.buy-now-btn {
  background-color: #845c5c;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

/* Start state */
.cart-enter {
  transform: translateX(100%);
}

/* Start state active */
.cart-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}

/* Exit state */
.cart-exit {
  transform: translateX(0);
}

/* Exit state active */
.cart-exit-active {
  transform: translateX(100%);
  transition: transform 300ms;
}

.phonefield {
  width: 250px;
  height: 6px;
  padding: 10px 10px 10px 10px;
  outline: 0;
  border: 2px solid rgba(105, 105, 105, 0.397);
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}

.phonefield:focus,
.phonefield:active {
  border: 2px solid #845c5c;
  box-shadow: 0 0 5px rgba(132, 92, 92, 0.5);
}

.phonelabel {
  color: #845c5c;
  display: block;
  font-weight: 600;
  font-size: 16px;
}

.namelabel {
  color: #845c5c;
  display: block;
  font-size: 16px;
  font-weight: 600;
}

.cart-container h4 {
  color: #000;
  font-weight: 300;
}

.namefield {
  border: 2px solid hsla(0, 0%, 41%, 0.397);
  border-radius: 5px;
  height: 6px;
  margin-bottom: 10px;
  margin-top: 10px;
  outline: 0;
  padding: 10px;
  transition: all 0.3s ease;
  width: 250px;
}

.emaillabel {
  color: #845c5c;
  display: block;
  font-size: 16px;
  font-weight: 600;
}

.emailfield {
  border: 2px solid hsla(0, 0%, 41%, 0.397);
  border-radius: 5px;
  height: 6px;
  margin-bottom: 10px;
  margin-top: 10px;
  outline: 0;
  padding: 10px;
  transition: all 0.3s ease;
  width: 250px;
}

.addresslabel {
  color: #845c5c;
  display: block;
  font-size: 16px;
  font-weight: 600;
}

.addressfield {
  border: 2px solid hsla(0, 0%, 41%, 0.397);
  border-radius: 5px;
  height: 6px;
  margin-bottom: 10px;
  margin-top: 10px;
  outline: 0;
  padding: 10px;
  transition: all 0.3s ease;
  width: 250px;
}

.SubmitOrder {
  background-color: #845c5c;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px 20px;
}

.success-message {
  width: 85%;
  padding: 20px;
  text-align: center;
  background-color: #f8f9fa; /* light grey */
  color: #333333; /* dark grey */
  border: 1px solid #dee2e6; /* border color */
  border-radius: 5px;
  margin: 20px 0;
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.butterimg {
  width: 50px;
}

@media screen and (max-width: 768px) {
  .cart-container {
    z-index: 4;
  }
}
