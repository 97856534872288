.footer {
    background-color: #f4f4eb;
    width: 100%;
    height: 200px;
    display: flex;
    padding-top: 20px;
    box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.2);
}

.footer-links {
    background-color: #f4f4eb;
    position: absolute;
    right: 300px;
    margin-top: 20px;
}

.footer-links a {
    color: #845c5c;
    margin-left: 20px;
    margin-right: 70px;
    
}

.footer-text {
    color: #845c5c;
    background-color: #f4f4eb;
    position: absolute;
    bottom: 0;
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-text p {
    margin: 0;
    padding: 0;
}

.footer-social {
    color: #8f5c5cf2;
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: 230px;
}

.footer-maintext {
    color: #845c5c;
    position: absolute;
    bottom: 80px;
    left: 300px;
    margin-right: 100px;
}

.Home-link-footer {
    font-size: calc(1px + 1.8vmin);
    color: #8f5c5cf2;
    text-decoration: none;
    animation: slideDown 1s ease-in-out;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .Origins-link-footer {
    font-size: calc(1px + 1.8vmin);
    color: #8f5c5cf2;
    text-decoration: none;
    animation: slideDown 1.5s ease-in-out;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .About-link-footer {
    font-size: calc(1px + 1.8vmin);
    color: #8f5c5cf2;
    text-decoration: none;
    animation: slideDown 2s ease-in-out;
    margin-left: 20px;
    margin-right: 20px;
  }

.Contact-link-footer {
    font-size: calc(1px + 1.8vmin);
    color: #8f5c5cf2;
    text-decoration: none;
    animation: slideDown 2.5s ease-in-out;
    margin-left: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: 768px) {
    .footer {
        background-color: #f4f4eb;
        width: 100%;
        height: 350px;
        justify-content: space-evenly;
        display: flex;
        box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.2);
    }

    .footer-links {
        background-color: #f4f4eb;
        position: absolute;
        right: 0px;
        margin-top: 84px;
    }
    
    .footer-links a {
        color: #845c5c;
        margin-left: 0px;
        margin-right: 50px;
        
    }

    .footer-add {
        position: absolute;
        top: -20px;
        left: 20px;
    }


    .footer-add2 {
        position: absolute;
        top: -20px;
        left: 137px;
    }

    .footer-email {
        padding-right: 50px;
        top: 0px;
    }
    .footer-phone {
        width: 250px;
        top: 15px;
    }

    .footer-text {
        color: #845c5c;
        background-color: #f4f4eb;
        position: absolute;
        bottom: 0;
        height: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .footer-text p {
        margin: 0;
        padding: 0;
    }
    
    .footer-social {
        color: #8f5c5cf2;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        margin-left: 0px;
        bottom: 200px;
        width: auto;
        margin-right: 0px;
    }

    .footer-social p {
        margin-right: 0px;
    }

    .footer-maintext {
        color: #845c5c;
        position: absolute;
        bottom: 50px;
        left: 20px;
        right: 20px;
        font-size: 13px;
        margin-right: 0px;
    }
  }