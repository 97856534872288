.header{
    z-index: 2;
    background-color: transparent;
    position: fixed;
}
.checkout-container{
    position: absolute;
    top: 30px;
    right: 15px;
  }

  
  .checkout-btn{
    font-size: 16px;
  }
  
  @media screen and (max-width: 768px) {
    .header {
      background-color: #f4f4eb;
      position: fixed;
      display: flex;
      width: 100%;
      height: 100px;
      justify-content: center;
      align-items: center;
      box-shadow: -5px 0 10px rgba(0, 0, 0, 0.5);
      top: 0;
    }

     .App-logo {
      position: relative;
      margin: 0;
    }

  }