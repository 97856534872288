body {
  margin: 0;
  padding: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.main-container {
  display: grid;
  grid-template-columns: 25% 75%;
  grid-gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cart-button {
  z-index: 4;
  width: 40px;
  position: fixed;
  right: 85px;
  top: 30px;
  transition: transform 0.3s ease;
}

.cart-button:hover {
  cursor: pointer;
  border-radius: 5px;
  background-color: #c5b6b6c1;
  transform: scale(1.2);
}

.cart-amount {
  z-index: 4;
  width: 20px;
  padding: auto;
  align-items: center;
  justify-content: center;
  position: fixed;
  display: flex;
  right: 60px;
  top: 25px;
  border-radius: 40px;
  border: 2px solid #aa6d6d92;
  transition: transform 0.3s ease;
}

.cart-amount:hover {
  transform: scale(1.2);
}

.side-bar {
  margin-left: 25%;
  display: grid;
  grid-template-rows: 55% 45%;
  grid-area: 1/1/2/2;
}

.sidebar-img {
  background-color: #f4f4eb;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 12px;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.2);
}

.sidebar-img img {
  max-width: 100%; /* set the maximum width of the image to be 100% of the container */
  max-height: 100%;
}

.Home-Navbar {
  display: flex;
  flex-direction: column;
  background-color: #f4f4eb;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  padding-top: 35%;
  padding-left: 1.5rem;
  justify-content: space-around;
  padding-bottom: 2rem;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: 100px;
}

.Home-Navbar a:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

.Home-Navbar a:hover img {
  filter: brightness(0.8);
}

.Home-Navbar a:hover p {
  color: #744949f2;
}

.Home-Navbar-mobile {
  display: flex;
  flex-direction: column;
  background-color: #f4f4eb;
  padding-top: 0;
  padding-left: 1.5rem;
  justify-content: space-around;
  padding-bottom: 2rem;
  margin-top: 70px;
}

.Home-main {
  grid-area: 1/2/2/3;
  margin-right: 10%;
  grid-template-rows: 65% 35%;
  grid-gap: 1.5rem;
  overflow: hidden;
}

.Home-link {
  font-size: calc(1px + 1.8vmin);
  display: flex;
  color: #8f5c5cf2;
  text-decoration: none;
  animation: slideDown 2.5s ease-in-out;
}

.Home-link img {
  display: absolute;
}

.Home-link p {
  margin-top: 25px;
  margin-left: 5px;
}

.Origins-link {
  font-size: calc(1px + 1.8vmin);
  display: flex;
  color: #8f5c5cf2;
  text-decoration: none;
  animation: slideDown 2.5s ease-in-out;
}

.Origins-link img {
  display: absolute;
}
.Origins-link p {
  margin-top: 25px;
  margin-left: 5px;
}

.Contact-link {
  font-size: calc(1px + 1.8vmin);
  display: flex;
  color: #8f5c5cf2;
  text-decoration: none;
  animation: slideDown 2.5s ease-in-out;
}

.Contact-link img {
  display: absolute;
}
.Contact-link p {
  margin-top: 25px;
  margin-left: 5px;
}

.About-link {
  font-size: calc(1px + 1.8vmin);
  color: #8f5c5cf2;
  display: flex;
  text-decoration: none;
  animation: slideDown 2.5s ease-in-out;
}

.About-link img {
  display: absolute;
}
.About-link p {
  margin-top: 25px;
  margin-left: 5px;
}

.main-img-ctn {
  margin-top: 100px;
  display: grid;
  grid-template-columns: 60% 40%;
}

.main-img {
  grid-area: 1/1/2/2;
  margin-bottom: 15px;
  margin-left: 10px;
  margin-right: 1rem;
  background-image: url("../../assets/buttersheno.jpg");
  background-size: cover;
  border-radius: 36px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.8);
}

.side-img {
  grid-area: 1/2/2/3;
}

.main-img p {
  color: #684343f2;
  font-size: medium;
  margin-top: 15%;
  margin-left: 25%;
  margin-right: 25%;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: rgb(244, 244, 235, 0.2);
  text-align: center;
  border-radius: 10px;
  animation: fadeIn 3s ease-in-out;
}

.side-img1 {
  grid-area: 1/2/2/3;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background-image: url("../../assets/side-img1.png");
  background-size: cover;
}

.side-img2 {
  grid-area: 1/3/2/4;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  background-image: url("../../assets/side-img2.png");
  background-size: cover;
}

.side-img3 {
  grid-area: 2/2/3/3;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  background-image: url("../../assets/side-img3.png");
  background-size: cover;
}

.side-img4 {
  grid-area: 2/3/3/4;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  background-image: url("../../assets/side-img4.png");
  background-size: cover;
}

.product-holder {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 1.8rem;
  height: 200px;
  margin-top: 20px;
}


.product-pic{
  width:110px;
  height:130px;
  margin: 0;
  padding: 0;
  background-image: url("../../assets/Sized.png");
  background-size: cover;
  border-radius: 10px;
}

.product-detail {
  margin-top: 20px;
}

.product {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  background-color: #f4f4eb;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 36px;
  margin: 15px;
  width: 90%;
}

.product h2 {
  font-size: 1.1rem;
  font-weight: 400;
  color: #744949f2;
  margin: 0;
  padding: 0;
  position: absolute;
  margin-top: -10px;
  width: 180px;
}

.product h3 {
  font-size: 1rem;
  font-weight: 400;
  color: #744949f2;
  margin-top: -60px;
  padding: 0;
}
.addtocart-btn {
  font-size: 1rem;
  font-weight: 400;
  position: absolute;
  margin-top: 40px;
  background-color: white;
  color: #744949f2;
  border: 1px solid #aa6d6d92;
  border-radius: 5px;
  padding: 2px 8px 2px 8px;
}

.addtocart-btn:hover {
  background-color: #845c5cc1;
  border: 1px solid #aa6d6d92;
  color: white;
  cursor: pointer;
}

.sideimg-low {
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: 10px;
  height: 200px;
  border-radius: 36px;
  background-image: url("../../assets/side-img3.png");
  background-size: cover;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.8);
}

.burger-button {
  display: none;
}

@media screen and (max-width: 768px) {
  /* Adjust 768px to the width at which you want to trigger mobile styling */
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .burger-button {
    position: fixed;
    width: 70px;
    top: 15px;
    left: 10px;
    display: block;
    z-index: 3;
  }



  .cart-bar {
    top: 0;
    right: 0px;
  }

  .cart-button {
    top: 25px;
    right: 30px;
    display: block;
    z-index: 5;
  }

  .cart-amount {
    top: 10px;
    right: 10px;
    z-index: 5;
    align-items: center;
    justify-content: center;
    position: fixed;
    display: flex;
    border-radius: 40px;
    border: 2px solid #aa6d6d92;
    transition: transform 0.3s ease;
  }

  .cart-container {
    z-index: 4;
  }

  .side-bar {
    display: none; /* Hide sidebar by default */
  }

  .mobile-nav-bar .side-bar {
    display: block; /* Show when burger menu is clicked */
    position: absolute;
    margin-left: 0;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background: #f4f4eb;
    z-index: 2;
  }

  .main-img p {
    color: #684343f2;
    font-size: larger;
    padding-top: 10%;
    padding-bottom: 30%;
    text-align: center;
    animation: fadeIn 3s ease-in-out;
    background-color: rgba(255, 255, 255, 0.423);
    width: 180px;
    height: 50px;
    margin-bottom: 50px;
  }

  .Home-main .main-img-ctn {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Home-main {
    width: 100%;
    grid-area: 1/2/2/3;
    margin-left: 5%;
    margin-right: 5%;
    grid-template-rows: 0;
    grid-gap: 1rem;
    overflow: hidden;
  }

  .Home-link p {
    margin-top: 25px;
    margin-left: 5px;
    font-size: 20px;
  }

  .Origins-link p {
    margin-top: 25px;
    margin-left: 5px;
    font-size: 20px;
  }

  .About-link p {
    margin-top: 25px;
    margin-left: 5px;
    font-size: 20px;
  }

  .Contact-link p {
    margin-top: 25px;
    margin-left: 5px;
    font-size: 20px;
  }

  .main-img {
    width: 85%;
  }
  .Home-main .main-img-ctn .side-img {
    width: 85%;
  }

  .sideimg-low {
    display: none;
  }

  .product-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    grid-template-columns: 50% 50%;
    grid-gap: 0rem;
    height: 450px;
    margin-top: 20px;
  }

  .product {
    margin-top: 60px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    gap: 0;
    background-color: #f4f4eb;
    display: flex;
    justify-content: space-evenly;
    border-radius: 36px;
    height: 150px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid hsla(0, 26%, 55%, 0.2);
    width: 85%;
  }

.product h2 {
  font-size: 1.1rem;
  font-weight: 400;
  color: #744949f2;
  margin: 0;
  padding: 0;
  position: absolute;
  margin-top: -5px;
  margin-right: 10px;
  width: 150px;
}

.product h3 {
  font-size: 1rem;
  font-weight: 400;
  color: #744949f2;
  margin-top: -60px;
  padding: 0;
}
.addtocart-btn {
  font-size: 1rem;
  font-weight: 400;
  position: absolute;
  margin-top: 45px;
  background-color: white;
  color: #744949f2;
  border: 1px solid #aa6d6d92;
  border-radius: 5px;
  padding: 2px 8px 2px 8px;
}

.addtocart-btn:hover {
  background-color: #845c5cc1;
  border: 1px solid #aa6d6d92;
  color: white;
  cursor: pointer;
}

}
