.origins-container {
  display: grid;
}

.origins-main {
  grid-template-columns: 22% 3% 74%;
  display: grid;
  width: 90%;
  margin-bottom: 100px;
  margin-top: 130px;
  margin-left: 6%;
  margin-right: 6%;
  border-radius: 20px;
  justify-content: space-around;
  transform: "translate(-50%,-50%)";
}

.react-parallax {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.react-parallax {
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.4) !important;
}


.origins-side {
  display: flex;
  flex-direction: column;
  background-color: #ccc8c4d6;
  padding-top: 55%;
  padding-left: 2rem;
  /* justify-content: space-around; */
  padding-bottom: 2rem;
  border-top-left-radius: 30px;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
  border-bottom-left-radius: 30px;
  /* border-top-right-radius: 30px; */
  /* margin-top: 100px; */
  color: white;
}

.origins-side a {
  color: black;
}

.origins-content {
  color: aliceblue;
}

.ori-cnt-div {
  background-image: url("../../assets/tilet.png");
  background-repeat: repeat;
  background-size: contain;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.4) !important;
  
}

.ori-cnt-div img {
  object-fit: fit;
}

.origins-content h1 {
  padding-left: 40%;
}

.ori-content {
  font-size: 18px;
  line-height: 1.67;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  transition: transform 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s;
}

.ori-content p {
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}


@media screen and (max-width: 768px) {

  .origins-side {
    display: none;
  }
  .ori-cnt-div {
    display: none;
  }

  .origins-main {
    display: flex;
    justify-self: center;
    width: 90%;
    margin-bottom: 100px;
    margin-top: 130px;
    margin-left: 0%;
    margin-right: 0%;
    border-radius: 20px;
    justify-content: space-around;
    transform: "translate(-50%,-50%)";
  }
  .react-parallax {
    overflow: hidden !important;
    position: inherit !important;
    margin-bottom: 300px;
  }
  .origins-content h1 {
    padding-left: 30%;
  }
}