@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

.card {
  display: grid;
  width: 92%;
  /* height: 600px; */
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
  /* margin-left: 10%; */
  margin-top: 100px;
  margin-bottom: 13px;
}

.card-main {
  width: inherit;
  height: inherit;
  margin-top: 70px;
  margin-left: 20px;
}

.card-inner {
  width: inherit;
  height: inherit;
  background-image: url("../../assets/ct-back.png");
  background-size: cover;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  display: grid;
  grid-template-columns: 60% 40%;
}

.circle {
  width: 100px;
  height: 100px;
  background: radial-gradient(#edd70f, #fbe3089e);
  border-radius: 50%;
  position: absolute;
  animation: move-up6 2s ease-in infinite alternate-reverse;
}

.circle:nth-child(1) {
  top: -4%;
  left: -3%;
}

.circle:nth-child(2) {
  bottom: -4%;
  right: 4%;
  animation-name: move-down1;
}

@keyframes move-up6 {
  to {
    transform: translateY(-10px);
  }
}

@keyframes move-down1 {
  to {
    transform: translateY(10px);
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;

  padding: 20px;

  position: relative;
}

.message {
  color: rgba(88, 87, 87, 0.822);
  font-size: 14px;
}

.flex {
  display: flex;
  width: 100%;
  gap: 30px;
}

.form label {
  position: relative;
}

.form label .input {
  width: 100%;
  padding: 10px 10px 20px 10px;
  outline: 0;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 5px;
}

.form label .input + span {
  position: absolute;
  left: 10px;
  top: 15px;
  color: grey;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.form label .input:placeholder-shown + span {
  top: 15px;
  font-size: 0.9em;
}

.form label .input:focus + span,
.form label .input:valid + span {
  top: 30px;
  font-size: 0.7em;
  font-weight: 600;
}

.form label .input:valid + span {
  color: green;
}

.input01 {
  width: 100%;
  height: 100px;
  padding: 10px 10px 20px 10px;
  outline: 0;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 5px;
}

.input-last {
  width: 100%;
  padding: 10px 10px 20px 10px;
  outline: 0;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 5px;
}

.form label .input-last + span {
  position: absolute;
  left: 10px;
  top: 15px;
  color: grey;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.form label .input-last:placeholder-shown + span {
  top: 15px;
  font-size: 0.9em;
}

.form label .input-last:focus + span,
.form label .input-last:valid + span {
  top: 30px;
  font-size: 0.7em;
  font-weight: 600;
}

.form label .input-last:valid + span {
  color: green;
}

.form label .input01 + span {
  position: absolute;
  left: 10px;
  top: 100px;
  color: grey;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.form label .input01:placeholder-shown + span {
  top: 105px;
  font-size: 0.9em;
}

.form label .input01:focus + span,
.form label .input01:valid + span {
  top: 105px;
  font-size: 0.7em;
  font-weight: 600;
}

.form label .input01:valid + span {
  color: green;
}

.fancy {
  background-color: transparent;
  border: 2px solid #cacaca;
  border-radius: 0px;
  width: 70%;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 390;
  letter-spacing: 2px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 8px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  user-select: none;
  font-size: 13px;
}

.fancy::before {
  content: " ";
  width: 1.7rem;
  height: 2px;
  background: #cacaca;
  top: 50%;
  left: 1.5em;
  position: absolute;
  transform: translateY(-50%);
  transform: translateX(230%);
  transform-origin: center;
  transition: background 0.3s linear, width 0.3s linear;
}

.fancy .text {
  font-size: 1.125em;
  line-height: 1.33333em;
  padding-left: 2em;
  display: block;
  text-align: left;
  transition: all 0.3s ease-in-out;
  text-transform: lowercase;
  text-decoration: none;
  color: #818181;
  transform: translateX(30%);
}

.fancy .top-key {
  height: 2px;
  width: 1.5625rem;
  top: -2px;
  left: 0.625rem;
  position: absolute;
  background: white;
  transition: width 0.5s ease-out, left 0.3s ease-out;
}

.fancy .bottom-key-1 {
  height: 2px;
  width: 1.5625rem;
  right: 1.875rem;
  bottom: -2px;
  position: absolute;
  background: white;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.fancy .bottom-key-2 {
  height: 2px;
  width: 0.625rem;
  right: 0.625rem;
  bottom: -2px;
  position: absolute;
  background: white;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.fancy:hover {
  color: white;
  background: #efde41;
}

.fancy:hover::before {
  width: 1.5rem;
  background: white;
}

.fancy:hover .text {
  color: white;
  padding-left: 1.5em;
}

.fancy:hover .top-key {
  left: -2px;
  width: 0px;
}

.fancy:hover .bottom-key-1,
.fancy:hover .bottom-key-2 {
  right: 0;
  width: 0;
}

.contactus-tag {
  padding-top: 12px;
  padding-left: 12px;
  font-size: xx-large;
  font-family: "Satisfy", Courier, monospace;
}

.contact-container {
  display: grid;
  grid-template-columns: 25% 75%;
  grid-gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.contact-img img {
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .contact-container {
    display: flex;
  }

  .card {
    width: 95%;
    margin-left: 5%;
    margin-right: 0%;
    align-self: center;
    display: flex;
  }
  .circle:nth-child(1) {
    top: -5%;
    left: -10%;
  }
  .circle:nth-child(2) {
    bottom: -4%;
    right: -2%;
    z-index: 4;
    opacity: 0.7;
    animation-name: move-down1;
  }
  .fancy { 
    padding: 10px 50px 10px 10px;
  }
  .fancy .text {
    font-size: 1.125em;
    line-height: 1.33333em;
    padding-left: 0px;
    display: block;
    text-align: left;
    transition: all 0.3s ease-in-out;
    text-transform: lowercase;
    text-decoration: none;
    color: #818181;
    transform: translateX(30%);
  }
}
